
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Montserrat);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Index Page
html, body {
  background-color: #000;
  height: 100%;
}

.bg-white {
  background-color: #FFFFFF;
}

.fs-container {
  background-image: url(../img/background.jpg);
  background-size: cover;
  display: table;
  height: 100%;
  opacity: 0;
  position: relative;
  transition: opacity 2s;
  width: 100%;

  &.visible {
    opacity: 1;
  }

  .fs-background {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .fs-valign {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
  }

  .fs-content {
    color: #fff;
    display: block;
    font-family: 'Montserrat';
    margin: 0px auto;
    padding: 30px;
    text-align: center;
    width: 85%;
    z-index: 2;
    position: relative;

    hr {
      height: 4px;
      margin: 5% 0px;
    }
    .name {
      font-size: 24px;
    }
    .about-me {
      font-size: 10px;
    }

    @media (min-width: $screen-sm) {
      width: 480px;

      .name {
        font-size: 32px;
      }
      .about-me {
        font-size: 14px;
      }
    }

    @media (min-width: $screen-md) {
    }

    @media (min-width: $screen-lg) {
      width: 640px;

      .name {
        font-size: 48px;
      }
    }
  }

  .fs-bottom {
    @include clearfix();
    background-color: rgba(0, 0, 0, 0.8);
    bottom: 0px;
    color: #fff;
    left: 0px;
    padding: 0px 15px;
    position: absolute;
    right: 0px;
    z-index: 3;

    a {
      display: inline-block;
      color: #fff;
      padding: 15px 15px;

      &:active, &:focus, &:hover {
        background-color: #fff;
        color: #000;
        text-decoration: none;
      }
    }
  }
}

